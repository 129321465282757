import React from 'react';

export default function HeroImg() {
  return (
    <div className="hero-img">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 673 465"
        style={{ enableBackground: 'new 0 0 673 465' }}
        preserveAspectRatio="none"
      >
        <g>
          <g>
            <g>
              <path
                className="st0"
                d="M589.4,10c6.2,0,11.2,5,11.2,11.2v306.5c0,6.2-5,11.2-11.2,11.2H83.6c-6.2,0-11.2-5-11.2-11.2V21.2
                                c0-6.2,5-11.2,11.2-11.2H589.4 M589.4,8H83.6c-7.3,0-13.2,5.9-13.2,13.2v306.5c0,7.3,5.9,13.2,13.2,13.2h505.8
                                c7.3,0,13.2-5.9,13.2-13.2V21.2C602.6,13.9,596.7,8,589.4,8L589.4,8z"
              />
            </g>
            <g>
              <path
                className="st0"
                d="M575.6,32v243.2H97.4V32H575.6 M577.6,30H95.4v247.2h482.3V30L577.6,30z"
              />
            </g>
            <path
              className="st1"
              d="M286.1,341l-36.9,70c0,0-13.9,22,18.4,22c-0.4,0,125.8,0,137.7,0c23,0,26-11,16-27c-7.5-13.7-36.5-65-36.5-65
                            "
            />
            <circle className="st1" cx="336.5" cy="307" r="15" />
            <g className="st2">
              <rect x="95.6" y="32" className="st0" width="482" height="245" />
            </g>
            <path
              className="st0"
              d="M444.4,111V67.5H229.6V111h-93.8v127.5h168.3V230h64.7v8.5h168.3V111H444.4z M535.1,236.5H370.9V230h73.5V113
                            h90.8V236.5z M231.6,228V112V83.5h210.7V112v116h-72.5h-66.7H231.6z M442.4,82.5H231.6v-13h210.7V82.5z M137.9,113h91.8v117h72.5
                            v6.5H137.9V113z"
            />
            <g>
              <rect
                x="305.7"
                y="155.2"
                className="st3"
                width="59.9"
                height="18"
              />
              <rect
                x="315.3"
                y="163.2"
                className="st0"
                width="40.6"
                height="1.7"
              />
            </g>
            <path
              className="st0 brackets"
              d="M305.9,123.2l19.9-9.3v4.4l-15,6.7v0.1l15,6.7v4.4l-19.9-9.3V123.2z M327,137.8l14.6-30.3h4.3l-14.6,30.3H327
                            z M365.4,127l-19.9,9.2v-4.4l15.4-6.7V125l-15.4-6.7v-4.4l19.9,9.2V127z"
            />
            <g>
              <circle className="st0" cx="313.3" cy="195.6" r="4.1" />
              <circle className="st0" cx="336.4" cy="195.9" r="4.1" />
              <circle className="st0" cx="359.4" cy="195.9" r="4.1" />
            </g>
          </g>
          <g>
            <g>
              <path
                className="st4"
                d="M19.5,459c-4.9,0-8.8-4-8.8-8.8V191.8c0-4.9,4-8.8,8.8-8.8h185.3c4.9,0,8.8,4,8.8,8.8v258.3
                                c0,4.9-4,8.8-8.8,8.8H19.5z"
              />
              <g>
                <path
                  className="st0"
                  d="M204.8,184c4.3,0,7.8,3.5,7.8,7.8v258.3c0,4.3-3.5,7.8-7.8,7.8H19.5c-4.3,0-7.8-3.5-7.8-7.8V191.8
                                    c0-4.3,3.5-7.8,7.8-7.8H204.8 M204.8,182H19.5c-5.4,0-9.8,4.4-9.8,9.8v258.3c0,5.4,4.4,9.8,9.8,9.8h185.3c5.4,0,9.8-4.4,9.8-9.8
                                    V191.8C214.7,186.4,210.3,182,204.8,182L204.8,182z"
                />
              </g>
            </g>
            <g className="st2">
              <rect x="25.2" y="197" className="st0" width="174" height="228" />
            </g>
            <g>
              <path
                className="st0"
                d="M198.2,198v226h-172V198H198.2 M199.2,197h-174v228h174V197L199.2,197z"
              />
            </g>
            <circle className="st1" cx="112.2" cy="440" r="7" />
            <rect
              x="25.6"
              y="213.5"
              className="st3"
              width="173.1"
              height="196"
            />
            <g>
              <rect x="67.2" y="325" className="st3" width="90" height="27" />
              <rect x="81.7" y="337" className="st0" width="61" height="2.5" />
            </g>
            <g>
              <circle className="st0" cx="78.3" cy="382.5" r="6" />
              <circle className="st0" cx="112.2" cy="383" r="6" />
              <circle className="st0" cx="146" cy="383" r="6" />
            </g>
            <path
              className="st0 brackets"
              d="M67.5,273.2l29.9-14v6.7l-22.6,10v0.1l22.6,10v6.7l-29.9-14V273.2z M99.2,295.1l21.9-45.6h6.4l-21.9,45.6
                            H99.2z M156.9,278.8L127,292.6V286l23.1-10.1v-0.1L127,265.8v-6.6l29.9,13.8V278.8z"
            />
          </g>
          <g>
            <g>
              <g>
                <path
                  className="st5"
                  d="M579.2,456c-4.9,0-8.8-4-8.8-8.8V270.8c0-4.9,4-8.8,8.8-8.8h77.3c4.9,0,8.8,4,8.8,8.8v176.3
                                    c0,4.9-4,8.8-8.8,8.8H579.2z"
                />
                <g>
                  <path
                    className="st0"
                    d="M656.5,263c4.3,0,7.8,3.5,7.8,7.8v176.3c0,4.3-3.5,7.8-7.8,7.8h-77.3c-4.3,0-7.8-3.5-7.8-7.8V270.8
                                        c0-4.3,3.5-7.8,7.8-7.8H656.5 M656.5,261h-77.3c-5.4,0-9.8,4.4-9.8,9.8v176.3c0,5.4,4.4,9.8,9.8,9.8h77.3
                                        c5.4,0,9.8-4.4,9.8-9.8V270.8C666.3,265.4,661.9,261,656.5,261L656.5,261z"
                  />
                </g>
              </g>
              <g className="st2">
                <path
                  className="st0"
                  d="M658.6,455h-80.5c-3.7,0-6.7-3-6.7-6.7V269.7c0-3.7,3-6.7,6.7-6.7H594l6,7h37l7-7h14.6c3.7,0,6.7,3,6.7,6.7
                                    v178.5C665.3,452,662.3,455,658.6,455z"
                />
              </g>
              <g>
                <path
                  className="st0"
                  d="M655.7,263c4.2,0,7.7,3.5,7.7,7.7v175.6c0,4.3-3.4,7.7-7.7,7.7H580c-4.2,0-7.7-3.5-7.7-7.7V270.7
                                    c0-4.3,3.4-7.7,7.7-7.7h13c0.4,0,0.8,0.2,1,0.5l4.9,6.3c0.6,0.8,1.6,1.3,2.6,1.3h34.2c1,0,1.9-0.4,2.5-1.1l5.6-6.5
                                    c0.2-0.3,0.6-0.4,1-0.4H655.7 M655.7,262h-11c-0.7,0-1.3,0.3-1.7,0.8l-5.6,6.5c-0.4,0.5-1.1,0.8-1.7,0.8h-34.2
                                    c-0.7,0-1.4-0.3-1.8-0.9l-4.9-6.3c-0.4-0.6-1.1-0.9-1.8-0.9h-13c-4.8,0-8.7,3.9-8.7,8.7v175.6c0,4.8,3.9,8.7,8.7,8.7h75.7
                                    c4.8,0,8.7-3.9,8.7-8.7V270.7C664.3,265.9,660.4,262,655.7,262L655.7,262z"
                />
              </g>
            </g>
            <g>
              <circle className="st0" cx="599.3" cy="415.6" r="3.3" />
              <circle className="st0" cx="617.8" cy="415.9" r="3.3" />
              <circle className="st0" cx="636.3" cy="415.9" r="3.3" />
            </g>
            <rect x="571.3" y="281" className="st3" width="93" height="156" />
            <g>
              <rect
                x="587.6"
                y="369.1"
                className="st3"
                width="60.4"
                height="18.1"
              />
              <rect
                x="597.3"
                y="377.1"
                className="st0"
                width="41"
                height="1.7"
              />
            </g>
            <path
              className="st0 brackets"
              d="M587.8,328.3l20.1-9.4v4.5l-15.1,6.7v0.1l15.1,6.7v4.5l-20.1-9.4V328.3z M609.1,343l14.7-30.6h4.3L613.4,343
                            H609.1z M647.8,332l-20.1,9.3v-4.4l15.5-6.8v-0.1l-15.5-6.8v-4.4l20.1,9.3V332z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}
